import React from "react";
//API for data
import {GetData} from "./GetData";
import {PostData} from "./PostData";
//Datatable Files
import Datatable from "react-data-table-component";
import "./css/dataTable.css"
//React JS Bootstrap Tags
import {Button, Row, Col, Card, Spinner, Modal, Alert, Form} from "react-bootstrap";
// Download Table data as file
import {CSVLink} from "react-csv";


class Donations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            donationsData: [],
            donationAllData: [],
            columns: [],
            dataLoader: true,
            OpeAlert: false,
            OpeAlertMessage: '',
            OpeAlertVariant: '',
            downloadDonationModal: false,
            start_date: '',
            start_dateErr: '',
            end_date: '',
            end_dateErr: '',
        }
        this.loadAllDonations = this.loadAllDonations.bind(this)
        this.handleDeleteDonation = this.handleDeleteDonation.bind(this)
        this.filterDonationList = this.filterDonationList.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        this.loadAllDonations()
    }

    loadAllDonations() {
        GetData('/frontCall/getAllDonations', this.state.userToken)
            .then(result => {
                this.setState({
                    donationAllData: result.response,
                    donationsData: result.response,
                    headers: [
                        {label: "Full Name", key: "full_name"},
                        {label: "Email", key: "email"},
                        {label: "Home Address", key: "home_address"},
                        {label: "Postcode", key: "post_code"},
                        {label: "Amount", key: "amount"},
                        {label: "Payment Type", key: "payment_type"},
                        {label: "Payment Status", key: "payment_status"},
                        {label: "Payment Date", key: "payment_date"},
                        {label: "Gift Aid Support", key: "gift_aid_support"},
                        {label: "Created", key: "created"},
                        {label: "Title", key: "title"}
                    ],
                    columns: [
                        {
                            name: <h6 className="font-weight-bold">Name</h6>,
                            selector: (row) => row.full_name,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Email</h6>,
                            selector: (row) => row.email,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Address</h6>,
                            selector: (row) => row.home_address,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Postcode</h6>,
                            selector: (row) => row.post_code,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Amount</h6>,
                            maxWidth: "80px",
                            selector: (row) => row.amount,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Payment Type</h6>,
                            maxWidth: "80px",
                            selector: (row) => row.payment_type,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Payment Status</h6>,
                            maxWidth: "80px",
                            selector: (row) => row.payment_status,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Payment Date</h6>,
                            selector: (row) => row.payment_date,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Gift Aid Support</h6>,
                            maxWidth: "80px",
                            selector: (row) => row.gift_aid_support,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Created</h6>,
                            selector: (row) => row.created,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Donation Cause</h6>,
                            selector: (row) => row.title,
                            sortable: true
                        },
                        {
                            name: <h6 className="font-weight-bold">Action</h6>,
                            cell: (row) => (
                                <Button variant="outline-danger" size={"sm"} className="line-height-1"
                                        onClick={() => {
                                            this.setState({
                                                donation_id: row.id,
                                                showDeleteModal: true,
                                            })
                                        }}>Delete</Button>
                            )
                        },
                    ],
                    dataLoader: false,
                });
            });
    }

    filterDonationList() {
        if (this.state.start_date === "") {
            this.setState({start_dateErr: "Choose Start date"})
        } else {
            this.setState({start_dateErr: ""})
        }
        if (this.state.end_date === "") {
            this.setState({end_dateErr: "Choose End date"})
        } else {
            this.setState({end_dateErr: ""})
        }

        if (this.state.start_date !== "" && this.state.end_date !== "") {
            let donationFilter = []
            this.state.donationAllData.forEach((donationsData) => {
                let [day, month, year] = donationsData.payment_date.split('/')
                if (new Date(this.state.start_date).getTime() < new Date(`${month}/${day}/${year}`).getTime()
                    && new Date(this.state.end_date).getTime() > new Date(`${month}/${day}/${year}`).getTime()) {
                    donationFilter.push(donationsData)
                }
            })
            this.setState({donationsData: donationFilter})
            this.handleClose()
        }
    }

    handleDeleteDonation() {
        this.setState({dataLoader: true})
        PostData("/frontCall/deleteDonations", {donation_id: this.state.donation_id}, this.state.userToken)
            .then((result) => {
                this.setState({
                    OpeAlert: true,
                    OpeAlertMessage: result.response,
                    OpeAlertVariant: 'success',
                    showDeleteModal: false,
                })
                this.loadAllDonations()
            })
    }

    handleClose() {
        this.setState({
            showDeleteModal: false,
            downloadDonationModal: false,
            start_date: "",
            start_dateErr: "",
            end_date: "",
            end_dateErr: "",
        })
    }

    render() {
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Donations</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Donations</li>
                                </ol>
                            </nav>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Alert show={this.state.OpeAlert} variant={this.state.OpeAlertVariant} className="rounded-lg">
                        <h6 className="m-0 font-weight-bold">{this.state.OpeAlertMessage}</h6>
                    </Alert>

                    <Modal
                        show={this.state.showDeleteModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Are you sure!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You want to Delete this Donation?
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.handleDeleteDonation}>Yes</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.downloadDonationModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Download Donation List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="form-group" controlId="start_date">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" name="start_date" placeholder="Start Date"
                                              value={this.state.start_date}
                                              onChange={(e) => this.setState({[e.target.name]: e.target.value})}/>
                                {this.state.start_dateErr
                                    && <Form.Text as={"div"} className="text-danger"> {this.state.start_dateErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="end_date">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="date" name="end_date" placeholder="End Date"
                                              value={this.state.end_date}
                                              onChange={(e) => this.setState({[e.target.name]: e.target.value})}/>
                                {this.state.end_dateErr
                                    && <Form.Text as={"div"} className="text-danger"> {this.state.end_dateErr} </Form.Text>}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="line-height-1" onClick={this.filterDonationList}>Submit</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Row className="mb-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <div className={"text-right mb-3"}>
                                        <CSVLink
                                            filename={"my-file.csv"}
                                            className="btn btn-primary"
                                            headers={this.state.headers}
                                            data={this.state.donationsData}>Export CSV</CSVLink>
                                        <Button variant={"primary"} onClick={() => {
                                            this.setState({downloadDonationModal: true})
                                        }} className={"ml-2"}>Filter Date</Button>
                                        {Object.keys(this.state.donationAllData).length !== Object.keys(this.state.donationsData).length
                                            && <Button variant={"primary"} onClick={() => {
                                                this.setState({donationsData: this.state.donationAllData})
                                            }} className={"ml-2"}>Show All Donations</Button>}
                                    </div>
                                    {this.state.dataLoader ?
                                        <div className="api-data-loader">
                                            <Spinner animation="grow" variant="info"/>
                                        </div> :
                                        <Datatable
                                            columns={this.state.columns}
                                            data={this.state.donationsData}
                                            pagination
                                            highlightOnHover
                                        />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
        )
    }
}

export default Donations;