import React from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {LoginData} from "./LoginData";
import "./Login.css";
import 'bootstrap-float-label/dist/bootstrap-float-label.min.css'
import App from "../App";
import {Button} from "react-bootstrap";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailErr: '',
            password: '',
            passwordErr: '',
            redirect: false,
            errorMessage: "",
        }
        this.userLogin = this.userLogin.bind(this)
        this.onChangeLoginFormData = this.onChangeLoginFormData.bind(this)
        let bodyTag = document.querySelector("body")
        bodyTag.classList.add("no-footer")
        bodyTag.classList.add("background")
    }

    userLogin() {
        LoginData('/frontCall/login', this.state).then((result) => {
            if (result.status) {
                const newStudent = {};
                newStudent.full_name = result.response.full_name;
                newStudent.email = result.response.email;
                newStudent.token = result.response.token;
                let student = [];
                student.push(newStudent);
                sessionStorage.setItem("userData", JSON.stringify(student));
                let sessionData = JSON.parse(sessionStorage.getItem('userData'));
                this.setState({redirect: true, errorMessage: "", fullName: sessionData[0].full_name});
            } else {
                if(result.message.email) {
                    this.setState({emailErr: result.message.email});
                } else {
                    this.setState({emailErr: ''});
                }
                if(result.message.password) {
                    this.setState({passwordErr: result.message.password});
                } else {
                    this.setState({passwordErr: ''});
                }
                if(!result.message.email && !result.message.password) {
                    this.setState({errorMessage: result.message});
                } else {
                    this.setState({errorMessage: ''});
                }
            }
        });
    }

    onChangeLoginFormData(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    render() {
        document.title = this.props.title;
        if (this.state.redirect && sessionStorage.getItem("userData")) {
            return (
                <BrowserRouter>
                    <Redirect to='/'/>
                    <Switch>
                        <Route exact path="/">
                            <App />
                        </Route>
                    </Switch>
                </BrowserRouter>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="fixed-background"></div>
                    <main className="default-transition vh-100">
                        <div className="container">
                            <div className="row h-100">
                                <div className="col-12 col-md-10 mx-auto my-auto">
                                    <div className="card auth-card">
                                        <div className="position-relative image-side p-0">
                                            <div className="login-left-text-wrapper">
                                                <p className=" text-white h2">Please use your admin credentials to login.</p>
                                                <p className="white mb-0">If you are not an admin, please contact current administrator.</p>
                                            </div>
                                        </div>
                                        <div className="form-side">
                                            <span className="logo-single"></span>
                                            {this.state.errorMessage && <div className="text-danger"> {this.state.errorMessage} </div>}
                                            <h6 className="mb-4">Login</h6>
                                            <form>
                                                <div className="form-group">
                                                    <label className="has-float-label mb-1">
                                                        <input type="text" className="form-control" id="login_email" name="email" placeholder="E-Mail"
                                                               value={this.state.email} onChange={this.onChangeLoginFormData}/>
                                                        <span>E-Mail</span>
                                                    </label>
                                                    {this.state.emailErr && <div className="text-danger"> {this.state.emailErr} </div>}
                                                </div>

                                                <div className="form-group">
                                                    <label className="has-float-label mb-1">
                                                        <input type="password" className="form-control" id="login_password" name="password" placeholder="Password"
                                                               value={this.state.password} onChange={this.onChangeLoginFormData}/>
                                                        <span>Password</span>
                                                    </label>
                                                    {this.state.passwordErr && <div className="text-danger"> {this.state.passwordErr} </div>}
                                                </div>

                                                <div className="d-flex justify-content-end">
                                                    <Button onClick={this.userLogin} variant="primary" className="btn-lg btn-shadow">LOGIN</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </React.Fragment>
            )
        }
    }
}

export default Login;