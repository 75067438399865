import React from "react";
//React JS Bootstrap Tags
import {Button} from "react-bootstrap";

const Pagination = ({pageButtons, totalUsers, usersPerPage, currentPageNum, currentPageFun}) => {
    let pages = [];

    for (let i = 1; i <= Math.ceil(pageButtons / usersPerPage); i++) {
        pages.push(i);
    }
    return (
        <div className='d-block w-100 text-right mt-2'>
            <span className="mr-2">Total Users: {totalUsers}</span>
            <div className="pagination_with_button_number">
                <Button
                    onClick={currentPageFun}
                    variant={"primary"}
                    value={1}
                    className="line-height-1 mx-1 iconsminds-arrow-left-2">
                </Button>

                {parseInt(currentPageNum) > 1 &&
                    <Button
                        onClick={currentPageFun}
                        variant={"primary"}
                        value={parseInt(currentPageNum) - 1}
                        className={"line-height-1 mx-1"}>
                        {parseInt(currentPageNum) - 1}
                    </Button>
                }
                <Button
                    onClick={currentPageFun}
                    variant={"primary"}
                    value={currentPageNum}
                    className={"active line-height-1 mx-1"}>
                    {currentPageNum}
                </Button>
                {pages.length < parseInt(currentPageNum) + 1 ? "" :
                    <Button
                        onClick={currentPageFun}
                        variant={"primary"}
                        value={parseInt(currentPageNum) + 1}
                        className={"line-height-1 mx-1"}>
                        {parseInt(currentPageNum) + 1}
                    </Button>
                }

                <Button
                    onClick={currentPageFun}
                    variant={"primary"}
                    value={pages.length}
                    className="line-height-1 mx-1 iconsminds-arrow-right-2">
                </Button>
            </div>
        </div>
    );
};

export default Pagination;
