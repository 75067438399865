import React from "react";
//API for data
import {PostData} from "./PostData";
import {GetData} from "./GetData";
//React JS Bootstrap Tags
import {Col, Row, Button, Modal, Card, Form, Alert, Spinner} from "react-bootstrap";

class Administrators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: sessionStorage.getItem('userData') ? (JSON.parse(sessionStorage.getItem('userData'))[0].token) : '',
            administratorsData: "",
            administratorsDataItems: "",
            administratorsId: "",
            administrator_full_name: "",
            administrator_full_nameErr: "",
            administrator_email: "",
            administrator_emailErr: "",
            administrator_password: "",
            administrator_passwordErr: "",
            OpeAlert: "",
            OpeAlertVariant: "",
            OpeAlertMessage: "",
            showCreateNewAdministratorModal: false,
            editAdministratorModal: false,
            showDeleteAdministratorModal: false,
            dataLoader: true,
        }
        this.onChangeCreateAdministratorsFormData = this.onChangeCreateAdministratorsFormData.bind(this)
        this.addUpdateAdministrator = this.addUpdateAdministrator.bind(this)
        this.handleShowModal = this.handleShowModal.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDeleteAdministrator = this.handleDeleteAdministrator.bind(this)
        this.loadAllAdministrators = this.loadAllAdministrators.bind(this)
    }

    componentDidMount() {
        this.loadAllAdministrators()
    }

    loadAllAdministrators() {
        GetData('/frontCall/getAllAdministrators', this.state.userToken)
            .then(result => {
                this.setState({administratorsData: result.response});
                const listItems = this.state.administratorsData.map((data) =>
                    <Col xs={12} md={6} xl={4} className="mb-3" key={data.id}>
                        <Card className="overflow-hidden">
                            <Card.Body className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
                                <div className="flex-fill">
                                    <p className="font-weight-medium mb-0">{data.full_name}</p>
                                    <p className="text-muted mb-0 text-small">{data.email} | {data.createdDate}</p>
                                </div>
                            </Card.Body>
                            <Card.Footer className="text-right bg-white">
                                <Button variant="outline-primary" className="line-height-1 ml-1" size={"sm"}
                                        onClick={() => {
                                            this.setState({
                                                administratorsId: data.id,
                                                administrator_full_name: data.full_name,
                                                administrator_email: data.email,
                                                showCreateNewAdministratorModal: true,
                                                editAdministratorModal: true,
                                            })
                                        } }>Edit</Button>
                                <Button variant="outline-danger" size={"sm"} className="line-height-1 ml-1"
                                        onClick={() => {
                                            this.setState({
                                                administratorsId: data.id,
                                                showDeleteAdministratorModal: true,
                                            })
                                        } }>Delete</Button>
                            </Card.Footer>
                        </Card>
                    </Col>);
                this.setState({administratorsDataItems: listItems, dataLoader: false});
            });
    }

    addUpdateAdministrator() {
        this.setState({dataLoader: true})
        let postDataObj = {
            full_name: this.state.administrator_full_name,
            email: this.state.administrator_email,
            password: this.state.administrator_password,
        };
        if (this.state.administratorsId) {
            postDataObj.id = this.state.administratorsId;
            delete postDataObj.password
        }
        let postApi = this.state.administratorsId ? "/frontCall/updateAdministrator" : "/frontCall/createAdministrator";
        PostData(postApi, postDataObj, this.state.userToken)
            .then(result => {
                if (result.status) {
                    this.setState({
                        showCreateNewAdministratorModal: false,
                        OpeAlert: true,
                        OpeAlertMessage: result.response,
                        OpeAlertVariant: 'success',
                        administrator_full_name: "",
                        administrator_email: "",
                        administrator_password: "",
                        administratorsId: "",
                    })
                    this.loadAllAdministrators();
                } else {
                    this.setState({dataLoader: false})
                    if (result.message.full_name) {
                        this.setState({administrator_full_nameErr: result.message.full_name});
                    } else {
                        this.setState({administrator_full_nameErr: ""});
                    }
                    if (result.message.email) {
                        this.setState({administrator_emailErr: result.message.email});
                    } else {
                        this.setState({administrator_emailErr: ""});
                    }
                    if (result.message.password) {
                        this.setState({administrator_passwordErr: result.message.password});
                    } else {
                        this.setState({administrator_passwordErr: ""});
                    }
                }
            })
    }

    onChangeCreateAdministratorsFormData(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleShowModal(e) {
        this.setState({
            showCreateNewAdministratorModal: true,
            editAdministratorModal: false,
            administratorsId: "",
        })
    }

    handleClose() {
        this.setState({
            showCreateNewAdministratorModal: false,
            showDeleteAdministratorModal: false,
            administratorsId: "",
            administrator_full_name: "",
            administrator_full_nameErr: "",
            administrator_email: "",
            administrator_emailErr: "",
            administrator_password: "",
            administrator_passwordErr: "",
        });
    }

    handleDeleteAdministrator() {
        this.setState({dataLoader: true,})
        PostData("/frontCall/deleteAdministrator", {administrator_id: this.state.administratorsId}, this.state.userToken)
            .then(result => {
                this.setState({
                    OpeAlert: true,
                    OpeAlertMessage: result.response,
                    OpeAlertVariant: 'success',
                })
            })
        this.setState({
            showDeleteAdministratorModal: false,
        });
        this.loadAllAdministrators()
    }

    render() {
        document.title = this.props.title;
        return (
            <main>
                <div className="container-fluid">
                    <Row>
                        <Col>
                            <h1>Administrators</h1>
                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb pt-0">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Administrators</li>
                                </ol>
                            </nav>
                            <Button variant="primary" className="mb-2 line-height-1" onClick={this.handleShowModal}>
                                Create New Administrator
                            </Button>
                            <div className="separator mb-5"></div>
                        </Col>
                    </Row>

                    <Alert show={this.state.OpeAlert} variant={this.state.OpeAlertVariant} className="rounded-lg">
                        <h6 className="m-0 font-weight-bold">{this.state.OpeAlertMessage}</h6>
                    </Alert>

                    <Modal
                        show={this.state.showCreateNewAdministratorModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>{this.state.editAdministratorModal ? "Edit" : "Create New"} Administrator</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="form-group" controlId="administrator_full_name">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" name="administrator_full_name" placeholder="Full Name"
                                              value={this.state.administrator_full_name} onChange={this.onChangeCreateAdministratorsFormData}/>
                                {this.state.administrator_full_nameErr && <Form.Text as={"div"} className="text-danger"> {this.state.administrator_full_nameErr} </Form.Text>}
                            </Form.Group>
                            <Form.Group className="form-group" controlId="administrator_email">
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control type="text" name="administrator_email" placeholder="E-Mail"
                                              value={this.state.administrator_email} onChange={this.onChangeCreateAdministratorsFormData}/>
                                {this.state.administrator_emailErr && <Form.Text as={"div"} className="text-danger"> {this.state.administrator_emailErr} </Form.Text>}
                            </Form.Group>
                            {
                                !this.state.administratorsId &&
                                <Form.Group className="form-group" controlId="administrator_password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="administrator_password" placeholder="Password"
                                                  value={this.state.administrator_password} onChange={this.onChangeCreateAdministratorsFormData}/>
                                    {this.state.administrator_passwordErr &&
                                        <Form.Text as={"div"} className="text-danger"> {this.state.administrator_passwordErr} </Form.Text>}
                                </Form.Group>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="line-height-1" onClick={this.addUpdateAdministrator}>Submit</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.showDeleteAdministratorModal}
                        onHide={this.handleClose}
                        dialogClassName="modal-dialog-centered"
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Are you sure!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You want to Delete this Administrator?
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button variant="primary" className="line-height-1" onClick={this.handleDeleteAdministrator}>Yes</Button>
                            <Button variant="danger" className="line-height-1" onClick={this.handleClose}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <Row>
                        {this.state.dataLoader ?
                            <div className="api-data-loader">
                                <Spinner animation="grow" variant="info"/>
                            </div> :
                            this.state.administratorsDataItems
                        }
                    </Row>
                </div>
            </main>
        )
    }
}

export default Administrators;